<template>
  <v-menu
    class="px-5"
    rounded="b-xl"
    bottom
    :max-width="$vuetify.breakpoint.smAndDown ? '100%': '50%'"
    transition="slide-y-transition"
  >
    <template v-slot:activator='{ on, attrs }'>
      <v-btn
        class="d-block justify-start align-center suspensives-points item__btn"
        v-bind="attrs"
        v-on="on"
        :width="$vuetify.breakpoint.smAndDown ? '100%': '50%'"
      >
          <div class="suspensives-points w-90 item__text">{{ currentItem }}</div>
        <v-btn icon>
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-btn>
    </template>
    <v-list v-if='items.length > 0' class="px-4">
      <v-list-item
        v-for='(item, index) in items'
        :key='index'
        @click="execute(item)"
      >
        <v-list-item-title class="item__text">{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    currentItem: 'Hacer Premium en aplicación Banqueo a los usuarios marcados'
  }),
  methods: {
    execute (item) {
      this.currentItem = item.name
      this.$emit('execute', item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #647485;
  }
  &__btn {
    text-transform: none !important;
  }
}
</style>
