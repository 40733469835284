<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" class="modal rounded-xl" max-width="45%" :min-height="$vuetify.breakpoint.smAndDown ? '350': '100%'" persistent>
    <v-card class="rounded-xl py-7">
        <p class="modal__title text-center">Los cambios se han realizado con éxito!</p>
        <v-card width="200" height="200" class="modal__border mx-auto my-5" :class="isVisible ? '': 'modal__card'">
          <transition name="bounce">
            <v-sheet
              v-if="isVisible"
              :max-width="229"
              class="ma-0 pa-0 transition-swing primary modal__border text-center"
              elevation="12"
              max-height="229"
              width="100%"
            >
              <v-icon dark size="150" class="my-5">
                mdi-check
              </v-icon>
            </v-sheet>
          </transition>
        </v-card>
        <v-card-actions class="d-flex justify-center pa-5 pb-0">
          <v-btn type="submit" class="modal__btn modal__btn--save mx-1" @click="closeSuccessModal">Aceptar</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>
export default {
  data: () => ({
    isVisible: false,
    counter: 0
  }),
  props: {
    startTime: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeSuccessModal () {
      this.isVisible = false
      this.$emit('closeBaseSuccessModal')
    }
  },
  watch: {
    async startTime () {
      if (this.startTime) {
        await setTimeout(() => {
          this.isVisible = true
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 1s;
}
.bounce-leave-active {
  animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.modal {
  &__border {
    border-radius: 40px !important;
  }
  &__title {
    font-size: 16px;
    line-height: 19px;
    color: #67757C;
  }
  &__card {
    border: 6px solid gray !important;
  }
  &__btn {
    text-transform: none !important;
    background: #FFF !important;
    border: 1px solid #707070 !important;
    font-size: 16px !important;
    color: #999999;
    box-shadow: none !important;
    &--save {
      background: #009EFB !important;
      color: #FFF;
    }
  }
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
