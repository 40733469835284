<template>
  <div id="app" class="d-flex flex-column align-center justify-center">
    <viewer :options="options" :images="images"
      @inited="inited"
      class="viewer" ref="viewer"
    >
      <template slot-scope="scope">
        <!-- <img v-for="src in scope.images" :src="src" :key="src">
        {{scope.options}} -->
        <v-btn v-if="scope.images" icon @click.stop="showImage(scope.images)">
          <v-icon class="ma-auto">mdi-image-multiple</v-icon>
        </v-btn>
      </template>
    </viewer>
  </div>  
</template>

<script>
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)

export default {
  data: () => ({
    options: {
      // toolbar: false,
      // navbar: false
    }
  }),
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    inited (viewer) {
      this.$viewer = viewer
    },
    showImage (images) {
      this.$viewerApi({
        images: images,
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
