import axios from 'axios'

export default {
  fetchCodeDetail: async ({ commit }, payload) => {
    const response = await axios.get(`api/admin/matriculas/codigos_referencia/${payload}`)
    const codeDetail = response.data
    commit('SET_CODE', codeDetail)
  },

  fetchCodeDetailMatriculados: async ({ commit }, payload) => {
    const response = axios.get(`api/admin/matriculas/matriculas/?codigo=${payload}`)
    const codeDetailMatriculados = response.data.results
    commit('SET_CODE_MATRICULADOS', codeDetailMatriculados)
  }

}
