import VueAxios from 'vue-axios'
import Vue from 'vue'
import axios from 'axios'
import store from '../store'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

axios.interceptors.request.use(async function (config) {
  if (store.state.token) {
    const token = store.state.token
    config.headers.Authorization = `Token ${token}`
    return config
  } else {
    // config.headers.Authorization = null
    return config
  }
})

Vue.use(VueAxios, axios)

export default axios
