import {set} from '@/utils/vuex'

export default {
  SET_TOKEN: set('token'),
  SET_USER: set('user'),
  SET_SIDEBAR_DRAWER(state, payload) {
    state.Sidebar_drawer = payload
  },
  SET_CUSTOMIZER_DRAWER(state, payload) {
    state.Customizer_drawer = payload
  },
  SET_SIDEBAR_COLOR(state, payload) {
    state.SidebarColor = payload
  },
  SET_LIST_CODES: set('codes'),
  SET_INSTITUCIONES: set('instituciones'),
  SET_SEARCH: set('searchGlobal'),
  SET_SECCIONES: set('secciones'),
  SET_PAYMENTS_ALERTS: set('alerts')
}
