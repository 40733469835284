import axios from 'axios'

export default {
  fetchUserDetail: async ({ commit }, payload) => {
    const response = await axios.get(`api/admin/matriculas/users/${payload}/`)
    const userDetail = response.data
    commit('SET_USER', userDetail)
  }

}
