export default {
  token: localStorage.getItem('token') || null,
  Sidebar_drawer: null,
  Customizer_drawer: false,
  SidebarColor: 'white',
  SidebarBg: '',
  user: null,
  reload: false,
  // references code
  codes: [],
  searchGlobal: '',
  instituciones: [],
  secciones: [],
  alerts: []
}
