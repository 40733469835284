import axios from '@/plugins/axios'

export default {

  login: async ({commit}, payload) => {
    const tokenResponse = await axios.post('api/admin/auth/login/', payload)
    const token = tokenResponse.data.token
    localStorage.setItem('token', token)
    commit('SET_TOKEN', token)
  },

  logout: ({commit}) => {
    localStorage.removeItem('token')
    commit('SET_TOKEN', null)
  },

  fetchUser: async ({commit}) => {
    const userResponse = await axios.get('api/admin/my_user/')
    const user = userResponse.data
    commit('SET_USER', user)
  },

  fetchCodes: async ({ commit }) => {
    const response = await axios.get('api/admin/matriculas/codigos_referencia/')
    const codes = response.data
    commit('SET_LIST_CODES', codes)
  },

  fetchSecciones: async ({ commit }) => {
    const seccionesResponse = await axios.get('api/matriculas/secciones/')
    const secciones = seccionesResponse.data
    commit('SET_SECCIONES', secciones)
  },

  fetchInstituciones: async ({ commit }) => {
    const institucionesResponse = await axios.get('api/instituciones/')
    const instituciones = institucionesResponse.data
    commit('SET_INSTITUCIONES', instituciones)
  },

  fetchAlerts: async ({ commit }) => {
    const alertsResponse = await axios.get('api/admin/matriculas/pagos/pendientes/')
    const alerts = alertsResponse.data
    commit('SET_PAYMENTS_ALERTS', alerts)
  }
}
