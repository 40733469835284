<template>
  <div id="app" class="d-flex flex-column align-center justify-center">
    <viewer
      :options="options"
      @inited="inited"
      class="viewer"
      ref="viewer"
      style="width: 100%;"
    >
      <img
        v-if="imageSrc"
        :src="imageSrc"
        class="voucher-image"
        @click.stop="showImage"
        style="cursor: pointer; width: 100%;"
      >
      <v-progress-circular v-else indeterminate rotate=""></v-progress-circular>
    </viewer>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)

export default {
  // components: {
  //   Viewer
  // },
  data () {
    return {
      options: {
        toolbar: false,
        navbar: false
      }
    }
  },
  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    dni: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    inited (viewer) {
      this.$viewer = viewer
    },
    showImage () {
      this.$viewer.show()
    }
  }
}
</script>

<style>
/* .image {
  height: 95px !important;
  max-width: 95px;
  cursor: pointer;
} */
</style>
