import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import filters from './filters';
import Vuebar from "vuebar";
import './plugins/moment'
import "./plugins/base";
import "./plugins/apexCharts";
import "./plugins/ckeditor";
// import "./plugins/ckeditor";
import VueSkycons from "vue-skycons";
import '@/assets/css/global.css'
import '@/assets/css/global.scss'
import VueClipboard from 'vue-clipboard2' // libreria para copiar en portapapeles

Vue.use(VueSkycons, {
  color: "#1e88e5",
});

Vue.config.productionTip = false

VueClipboard.config.autoSetContainer = true

Vue.use(Vuebar)
Vue.use(filters)
Vue.use(VueClipboard)

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app")
