import Vue from "vue";
import Router from "vue-router";
// import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/dashboard/",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Inicio",
          path: "index/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/Index"),
        },
        {
          name: "Inscriptions",
          path: "inscriptions/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/inscriptions/Index"),
          meta: { permission: "matriculas.view_formulario" }
        },
        {
          name: "Matriculas",
          path: "matriculas/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/matriculas/Index"),
          meta: { permission: "matriculas.view_matricula" }
        },
        {
          name: "Sections",
          path: "sections/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/sections/Index"),
          meta: { permission: "matriculas.view_seccion" }
        },
        {
          name: "SectionDetail",
          path: "sections/:id",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/sections/Detail"),
          meta: { permission: "matriculas.view_seccion" }
        },
        {
          name: "Codes",
          path: "codes/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/codes/Index"),
          meta: { permission: "matriculas.view_codigoreferencia" }

        },
        {
          name: "CodeDetail",
          path: "codes/:id",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/codes/Detail"),
          meta: { permission: "matriculas.change_codigoreferencia" }
        },
        {
          name: "NewInscription",
          path: "inscriptions/create/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/inscriptions/Create"),
          meta: { permission: "matriculas.add_formulario" }
        },
        {
          name: "UpdateInscription",
          path: "inscriptions/:id",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/inscriptions/Update"),
          meta: { permission: "matriculas.change_formulario" }
        },
        {
          name: "Users",
          path: "users/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/users/Index"),
          meta: { permission: "users.view_user" },
          props: route => ({
            search: route.query.search,
            premium: route.query.premium,
            seccion: route.query.seccion,
            institucion: route.query.institucion,
            tipoPago: route.query.tipo_pago,
            page: parseInt(route.query.page)
          })
        },
        {
          name: "UserDetail",
          path: "users/:id",
          beforeEnter: ifAuthenticated,
          meta: { permission: "users.change_user" },
          component: () => import("@/views/dashboard/users/Detail"),
        },
        {
          name: "Payments",
          path: "payments/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/payments/Index"),
          meta: { permission: "matriculas.view_pago" },
          props: route => ({
            fecha_inicio: route.query.fecha_inicio,
            fecha_fin: route.query.fecha_fin,
            seccion: route.query.seccion,
            page: parseInt(route.query.page)
          }),
        },
        {
          name: "Notes",
          path: "notes/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/notes/Index"),
          meta: { permission: "notas.view_nota" },
        },
        {
          name: "NewNote",
          path: "notes/create/",
          beforeEnter: ifAuthenticated,
          component: () => import("@/views/dashboard/notes/Create"),
          meta: { permission: "notas.add_nota" },
        },
        {
          name: "UpdateNote",
          path: "notes/:id",
          beforeEnter: ifAuthenticated,
          meta: { permission: "notas.change_nota" },
          component: () => import("@/views/dashboard/notes/Update")
        },
        {
          name: "Simulacros",
          path: "simulacros/",
          beforeEnter: ifAuthenticated,
          meta: { permission: "banqueo.view_simulacro" },
          component: () => import("@/views/dashboard/simulacros/Index"),
        },
      ],
    },

    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "",
          beforeEnter: ifNotAuthenticated,
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        {
          name: "FullLogin",
          path: "fulllogin",
          component: () => import("@/views/authentication/FullLogin"),
        },
        {
          name: "BoxedRegister",
          path: "boxedregister",
          component: () => import("@/views/authentication/BoxedRegister"),
        },
        {
          name: "FullRegister",
          path: "fullregister",
          component: () => import("@/views/authentication/FullRegister"),
        },
        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
      ],
    },
    {
      path: '*',
      redirect: '/authentication'
    }
  ],
});

import NProgress from "nprogress";
import { ifAuthenticated, ifNotAuthenticated } from "@/router/guards";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
