<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <router-view/>
    <confirm-modal ref="confirm"/>
    <loading-modal ref="loading"/>
  </v-app>

</template>

<script>
import ConfirmModal from '@/components/emergentesGlobales/ConfirmModal'
import LoadingModal from '@/components/emergentesGlobales/LoadingModal'
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    ConfirmModal,
    LoadingModal,
  },
  async mounted () {
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$loading = {
      open: this.$refs.loading.open,
      close: this.$refs.loading.close
    }
    // console.log(process.env.BASE_API_URL)
  },
  computed: {
    ...mapGetters(['permissions']),
    viewPermission() {
      return this.$route.meta.permission
    }
  },
  // beforeRouteEnter() {
  //   console.log('asd')
  //   if (this.permissions.length === 0) {
  //       return
  //   }
  //   if (!this.permissions.includes(this.viewPermission)) {
  //     alert('No tienes permiso para ver esto')
  //     this.$router.replace({ name: 'Inicio' })
  //   }
  // },
  watch: {
    '$route.path': {
      handler () {
        if (this.viewPermission === undefined) {
          return
        }
        if (this.permissions.length === 0) {
          this.$router.replace({ name: 'Inicio' })
          return
        }
        if (!this.permissions.includes(this.viewPermission)) {
          alert('No tienes permiso para ver esto')
          this.$router.replace({ name: 'Inicio' })
        }
      },
      immediate: true
    }
  }
}
</script>
