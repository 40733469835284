<template>
  <v-container>
    <v-row class="ma-0 pa-0">
      <v-col
          cols="12"
          md="6"
          class="ma-0 pa-0 d-flex justify-end"
      >
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              v-model="dateFormattedStart"
              label="Filtrar desde:"
              persistent-hint
              append-icon="mdi-calendar"
              v-bind="attrs"
              @blur="date = parseDate(dateFormattedStart)"
              v-on="on"
              outlined
              dense
              clearable
              @click:clear="$emit('clearStartDate')"
              style="max-width: 210px;"
              class="ma-auto pa-0 mr-4"
              autocomplete="off"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="ma-0 pa-0 d-flex justify-end"
      >
        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              v-model="dateFormattedEnd"
              label="Filtrar hasta:"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
              clearable
              @click:clear="$emit('clearEndDate')"
              style="max-width: 210px;"
              autocomplete="off"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    startDateInitial: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    endDateInitial:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    startDate: '',
    endDate: '',
    dateFormattedStart: null,
    dateFormattedEnd: null,
    menu1: false,
    menu2: false
  }),

  computed: {
    computedDateFormattedStart () {
      return this.formatDate(this.startDate)
    },
    computedDateFormattedEnd () {
      return this.formatDate(this.endDate)
    }
  },

  watch: {
    startDate () {
      this.dateFormattedStart = this.formatDate(this.startDate)
      this.$emit('sendStartDate', this.startDate)
    },
    endDate () {
      this.dateFormattedEnd = this.formatDate(this.endDate)
      this.$emit('sendEndDate', this.endDate)
    }
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  created () {
    const dateInicio = this.$route.query.fecha_inicio
    if (dateInicio) {
      this.startDate = dateInicio || null
    }
    const dateFin = this.$route.query.fecha_fin
    if (dateFin) {
      this.endDate = dateFin || null
    }
  }
}
</script>
