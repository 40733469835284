// https://vuex.vuejs.org/en/getters.html

export default {
  isAuthenticated: state => !!state.token,
  permissions: state => {
    if (state.user === null){
      return []
    }
    const basePermissions = state.user.permisos
    if (state.user.is_superuser) {
      basePermissions.push("is_superuser")
      basePermissions.push("is_staff")
    }

    if (state.user.is_staff) {
      basePermissions.push("is_staff")
    }
    return basePermissions
  }
}
